<template>
  <div id="trade-layout">
    <div class="container tw-px-4">
      <div class="lg:tw-px-4 tw-mb-12">
        <div class="sm:tw-flex">
          <button
            @click="$router.go(-1)"
            class="tw-w-9 tw-h-9 tw-rounded-full back__button tw-flex tw-justify-center tw-items-center tw-mb-4 sm:tw-mb-0 tw-mr-2"
          >
            <img src="@/assets/img/arrow-back.svg" alt="arrow" />
          </button>
          <div>
            <h3 class="tw-text-2xl tw-font-bold tw-pb-1">
              Trade
            </h3>
            <p class="tw-text-black-light tw-mb-6">
              Invest in promising startups on this list
            </p>
          </div>
        </div>
        <ListingCard />
        <div class="tw-bg-white tw-rounded-lg tw-px-4 tw-pt-6 tw-mt-16">
          <TableHead
            :options="options"
            :tab="option"
            label="options"
            @emitOption="emitOption"
          />
          <RaisingTable v-if="option === 'Listings'" />
          <RaisedCard v-if="option === 'Secondaries'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ListingCard from "@/components/trade/ListingCard.vue";
import TableHead from "@/components/tables/TableHeader";
import RaisingTable from "@/components/tables/RaisingTable.vue";
import RaisedCard from "@/components/invest/RaisedCard.vue";

export default {
  name: "Trade",

  components: { ListingCard, RaisingTable, TableHead, RaisedCard },

  data() {
    return {
      options: ["Listings", "Secondaries"],
      option: "",
    };
  },

  computed: {
    ...mapState({
      smallLoading: (state) => state.smallLoading,
    }),
  },

  methods: {
    emitOption(option) {
      switch (option) {
        case "Listings":
          this.option = "Listings";
          break;
        case "Secondaries":
          this.option = "Secondaries";
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#trade-layout {
  width: 100%;
  overflow: hidden;

  .container {
    @include fadeIn;

    @media screen and (min-width: 1024px) {
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .table-container {
    overflow: auto;
  }

  .message {
    max-width: 250px;
  }
}
</style>
