<template>
  <div id="raised-card">
    <div class="tw-bg-white tw-rounded-lg tw-py-8 tw-px-4">
      <div
        v-if="!loading"
        class="tw-grid sm:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-2 tw-gap-y-8 md:tw-px-4"
      >
        <div v-for="(token, index) in companiesRaised" :key="index" class="">
          <div
            class="tw-grid tw-grid-cols-4 tw-gap-x-2 tw-content-center tw-items-center tw-cursor-pointer"
            @click="
              $router.push({
                name: 'TokenTrade',
                params: { symbol: token.symbol, id: token._id },
              })
            "
          >
            <div
              class="tw-w-full tw-flex tw-justify-center tw-bg-gray-light tw-rounded-lg tw-p-4"
            >
              <img class="" :src="token.image" :alt="token.name" />
            </div>
            <div class="tw-col-start-2 tw-col-end-5">
              <h3 class="tw-text-lg tw-font-bold tw-mb-2">{{ token.name }}</h3>
              <p class="tw-flex tw-justify-between sm:tw-justify-start">
                <span>
                  {{ formatAmountToDollar(token.price.exchange, 2, "USD") }}
                </span>
                <span
                  class="tw-bg-success-lightest tw-text-success tw-text-sm tw-rounded-2xl tw-py-1 tw-px-4 tw-ml-4"
                >
                  +{{ token.percentage }}%
                  <span class="tw-text-black-light tw-ml-1">24h</span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <SmallLoader v-if="loading" />
      <div
        v-if="$route.name !== 'Invest'"
        class="tw-flex tw-justify-end tw-mr-2 tw-my-8"
      >
        <Pagination
          :totalPage="pages"
          :currentPage="page"
          :maxVisible="5"
          @pageChange="gotoPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { formatAmountToDollar } from "@/utils/helpers.js";
import Pagination from "@/components/navigation/Pagination";

export default {
  name: "RaisedCard",

  components: { Pagination },
  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapState({
      companiesRaised: (state) => state.Asset.companiesRaised,
      page: (state) => state.Asset.raisedPage,
      pages: (state) => state.Asset.raisedPages,
    }),
  },

  mounted() {
    this.getDetails();
  },

  methods: {
    ...mapActions("Asset", ["getCompaniesRaised"]),

    formatAmountToDollar,

    async getDetails() {
      this.loading = true;
      let limit = 6;
      if (this.$route.name !== "Invest") limit = 10;
      const payload = { page: 1, limit: limit };
      await this.getCompaniesRaised(payload);
      this.loading = false;
    },

    async gotoPage(page) {
      this.loading = true;
      let limit;
      if (this.$route.name !== "Invest") limit = 10;
      const payload = {
        page: page,
        limit: limit,
      };
      await this.getCompaniesRaised(payload);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
