<template>
  <div id="order-book-table" class="md:tw-flex">
    <div class="tw-relative tw-w-full tw-bg-white tw-rounded-lg tw-py-4">
      <div class="tw-relative">
        <TableHead
          :options="computedOptions"
          :tab="option"
          label="options"
          @emitOption="emitOption"
        />
        <div
          v-if="$route.name === 'InvestOrderbook'"
          class="tw-flex tw-justify-end"
        >
          <button
            class="sm:tw-absolute sm:tw-right-0 sm:tw--top-2 tw-flex tw-items-center tw-bg-gray-light tw-rounded tw-text-black-light hover:tw-text-white tw-py-2 tw-px-4 tw-mt-4"
            @click="showFilter = !showFilter"
          >
            <span class="tw-hidden sm:tw-block">Filter</span>
            <img
              class="sm:tw-ml-4"
              src="@/assets/img/filter.svg"
              alt="filter icon"
            />
          </button>
        </div>
        <FilterComponent
          v-if="showFilter"
          class="tw-absolute tw-right-0 tw-top-16 sm:tw-top-0"
          title="Filter Orders"
          :options="filterOptions"
          @close="showFilter = !showFilter"
          @filter="filter"
        />
      </div>
      <div class="tw-w-full tw-overflow-auto">
        <table class="table tw-w-full">
          <thead v-if="tableRows.length !== 0" class="header">
            <tr class="header-row">
              <th
                class="header-data tw-font-normal tw-py-6"
                ref="tableHead"
                :style="gridStyling"
              >
                <div
                  class="tw-justify-self-start"
                  v-for="(header, index) in tableHeaders"
                  :key="'header' + index"
                >
                  {{ header }}
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr class="body-row">
              <td class="body-data tw-min-h-full" ref="tableRow">
                <div
                  class="data tw-py-6 tw-border-t tw-border-gray-light hover:tw-bg-gray-lightest"
                  v-for="(details, index) in tableRows"
                  :key="'row' + index"
                  :style="gridStyling"
                >
                  <div
                    class="tw-self-center"
                    v-for="(detail, index) in details"
                    :key="'column' + index"
                  >
                    <p
                      v-html="detail"
                      class="tw-text-sm"
                      :class="{
                        [detail.toLowerCase()]: availableStatuses.includes(
                          detail
                        ),
                      }"
                    ></p>
                  </div>
                  <button
                    v-if="details.status.includes('Pending')"
                    class="btn tw-self-center tw-bg-error tw-text-white tw-py-2 tw-rounded"
                    @click="cancel(details.id, index)"
                  >
                    <span>Cancel</span>
                  </button>
                </div>
                <EmptyState v-if="tableRows.length === 0 && !smallLoading">
                  <template v-slot:message>
                    <p class="message tw-text-center tw-text-gray tw-my-3">
                      There has been no orders on your account.
                    </p>
                  </template>
                </EmptyState>
                <SmallLoader v-if="smallLoading && table === 'orderTable'" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="this.$route.name !== 'Overview'"
        class="tw-flex tw-justify-end tw-mr-2"
      >
        <Pagination
          v-if="$route.name !== 'Invest'"
          :totalPage="pages"
          :currentPage="page"
          :maxVisible="5"
          @pageChange="gotoPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TableHead from "@/components/tables/TableHeader";
import Pagination from "@/components/navigation/Pagination";
import FilterComponent from "@/components/general/FilterComp.vue";
import EmptyState from "@/components/notification/EmptyState.vue";
import { formatISODate, formatAmountToDollar } from "@/utils/helpers.js";

export default {
  name: "OrderBookTable",

  components: { TableHead, Pagination, FilterComponent, EmptyState },

  data() {
    return {
      showFilter: false,
      options: ["Buy Orders", "Sell Orders"],
      allOptions: ["All Orders", "Buy Orders", "Sell Orders"],
      option: "",
      filterOptions: ["Completed", "Pending", "Failed"],
      currentOption: "",
      availableStatuses: ["Pending", "Completed", "Cancelled"],
      tableHeaders: [
        "",
        "Amount of token",
        "Price per token",
        "Total",
        "Status",
        "Date",
        "Action",
      ],
      tableRows: [],
      table: "",
    };
  },

  computed: {
    ...mapState({
      smallLoading: (state) => state.smallLoading,
      orderBook: (state) => state.Asset.orderBook,
      page: (state) => state.Asset.orderPage,
      pages: (state) => state.Asset.orderPages,
    }),
    computedOptions() {
      if (this.$route.name === "InvestOrderbook") {
        return this.allOptions;
      } else {
        return this.options;
      }
    },
    gridStyling() {
      return {
        "grid-template-columns": `${10}px ${200}px repeat(${5}, ${120}px)`,
      };
    },
  },

  methods: {
    ...mapActions("Asset", ["getOrderBook", "cancelOrder"]),

    filter(status) {
      if (!status) return;
      const payload = {
        page: 1,
        type: this.currentOption,
        limit: 10,
        status,
      };
      this.tableRows = [];
      this.table = "orderTable";
      this.getOrderBook(payload);
    },

    emitOption(option) {
      this.table = "orderTable";
      this.tableRows = [];
      let type;
      let limit;

      switch (option) {
        case "All Orders":
          type = null;
          this.currentOption = null;
          this.option = "All Orders";
          break;
        case "Buy Orders":
          type = "Buy";
          this.currentOption = "Buy";
          this.option = "Buy Orders";
          break;
        case "Sell Orders":
          type = "Sell";
          this.currentOption = "Sell";
          this.option = "Sell Orders";
          break;

        default:
          break;
      }
      this.$route.name === "Invest" ? (limit = 5) : (limit = 10);
      const payload = { page: 1, type, limit: limit };
      this.getOrderBook(payload);
    },

    gotoPage(page) {
      this.tableRows = [];
      this.table = "orderTable";
      let limit;
      this.$route.name === "Invest" ? (limit = 5) : (limit = 10);
      const payload = {
        page: page,
        type: this.currentOption,
        limit: limit,
      };
      this.getOrderBook(payload);
    },

    updateTableRow(orders) {
      this.table = "";
      let status;
      this.tableRows = orders.map((order) => {
        if (order.status === "Active") status = "Pending";
        if (order.status === "Fulfilled") status = "Completed";
        if (order.status === "Terminated") status = "Cancelled";
        return {
          id: `<span class="tw-hidden">${order._id}</span>`,
          amount: `<span class="tw-flex"><img
                      src=${order.token.image}
                      class="tw-mr-8 tw-w-8"
                      alt="blue cone"
                      loading="lazy"
                    />${order?.amount.toFixed(2)} (${
            order.token.symbol
          })</span>`,
          price: formatAmountToDollar(order?.price, 2, "USD"),
          total: formatAmountToDollar(order?.amount * order?.price, 2, "USD"),
          status: status,
          date: `${formatISODate(order.createdAt)} `,
        };
      });
    },

    async cancel(data) {
      this.tableRows = [];
      this.table = "orderTable";
      const id = data.split(">")[1].split("<")[0];
      const response = await this.cancelOrder(id);
      if (response) {
        let limit;
        this.$route.name === "Invest" ? (limit = 5) : (limit = 10);
        const payload = {
          page: this.page,
          type: this.currentOption,
          limit: limit,
        };
        this.getOrderBook(payload);
      }
    },
  },

  watch: {
    orderBook(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateTableRow(this.orderBook);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#order-book-table {
  @include fadeIn;

  .table {
    .header {
      .header-row {
        .header-data {
          display: grid;
          column-gap: 2rem;
        }
      }
    }
    .table-body {
      .body-row {
        .body-data {
          .data {
            display: grid;
            column-gap: 2rem;
            font-size: 0.7rem;

            .btn {
              max-width: 5.4rem;
            }

            // Added with javascript
            .completed,
            .pending,
            .cancelled {
              text-align: left;
              padding: 0.5rem;
              border-radius: 5px;
            }
            .completed {
              color: $success;
              background-color: $success-light;
              max-width: 5.4rem;
            }
            .pending {
              color: $warning;
              background-color: $warning-light;
              max-width: 4.2rem;
            }
            .cancelled {
              color: $error;
              background-color: $error-light;
              max-width: 5rem;
            }
          }
        }
      }
    }
  }
}
</style>
