<template>
  <div id="transactions">
    <div class="container tw-px-4">
      <div class="tw-flex lg:tw-px-4">
        <BackBtn class="tw-hidden sm:tw-block tw-mr-6" />
        <div>
          <h3 class="tw-text-2xl tw-font-bold tw-pb-1">
            Order Book
          </h3>
          <p class="tw-text-black-light tw-mb-10">
            You have made a total of {{ totalOrder }} orders
          </p>
        </div>
      </div>
      <div
        class="tw-bg-white tw-rounded-lg lg:tw-mx-4 tw-px-4 tw-py-6 tw-my-14"
      >
        <OrderBookTable :table="table" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrderBookTable from "@/components/tables/OrderBookTable";
import TableHeader from "@/components/tables/TableHeader";
import BackBtn from "@/components/general/BackBtn";

export default {
  name: "Orderbook",

  components: {
    OrderBookTable,
    TableHeader,
    BackBtn,
  },

  data() {
    return {
      option: "",
      currentOption: "Order Book",
      table: "",
    };
  },

  computed: {
    ...mapState({
      transactions: (state) => state.organisationModule.orgTransactions,
      totalTransactions: (state) => state.organisationModule.totalTransactions,
      totalOrder: (state) => state.Asset.totalOrder,
      page: (state) => state.organisationModule.page,
      pages: (state) => state.organisationModule.pages,
    }),
  },
};
</script>

<style lang="scss" scoped>
#transactions {
  width: 100%;
  overflow: hidden;

  .container {
    @include fadeIn;

    @media screen and (min-width: 1024px) {
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
