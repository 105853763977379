<template>
  <div id="filter-component" class="tw-z-10">
    <div class="container tw-bg-white tw-rounded tw-p-4">
      <div class="tw-flex tw-justify-between tw-items-center tw-mb-8">
        <h3 class="tw-text-lg">{{ title }}</h3>
        <img
          class="tw-h-4 tw-w-4 tw-cursor-pointer"
          src="https://res.cloudinary.com/djalafcj9/image/upload/v1635485821/getequityV2/close_muxdyb.png"
          alt="close icon"
          @click="$emit('close')"
        />
      </div>
      <ul>
        <li v-for="(option, index) in options" :key="index">
          <label
            class="tw-flex tw-justify-between tw-items-center tw-cursor-pointer tw-mb-5"
            :for="option.name"
          >
            <div class="tw-flex tw-items-center">
              <div
                class="tw-w-8 tw-h-8 tw-rounded-full tw-border tw-mr-4"
                :class="option.name"
              ></div>
              <div class="tw-text-black-light tw-text-lg">
                {{ option.name }}
              </div>
            </div>
            <input
              type="radio"
              v-model="filterValue"
              name="filter"
              :id="option.name"
              :value="option.value"
            />
          </label>
        </li>
      </ul>
      <!-- <div class="tw-flex tw-justify-end">
        <button
          class="tw-bg-blue-pastel tw-rounded tw-cursor-pointer tw-text-center tw-mb-2 tw-text-black-light tw-py-1 tw-px-4"
          @click="filterValue = ''"
        >
          Clear
        </button>
      </div> -->
      <Btn title="Filter" @click="filterOrder" />
    </div>
  </div>
</template>

<script>
import Btn from "@/components/general/BtnComponent.vue";

export default {
  name: "FilterComponent",

  components: { Btn },

  props: {
    options: { type: Array, default: () => [], required: true },
    title: { type: String, default: () => "", required: true },
  },

  data() {
    return {
      filterValue: "",
    };
  },
  emits: ["filter", "close"],

  methods: {
    filterOrder() {
      this.$emit("filter", this.filterValue);
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
#filter-component {
  .container {
    width: 254px;
    box-shadow: 0px 2px 4px rgba(44, 73, 137, 0.3);
  }

  .Successful,
  .Completed {
    background: rgba(39, 174, 96, 0.1);
  }

  .Pending {
    background: rgba(243, 139, 0, 0.1);
  }

  .Failed {
    background: rgba(219, 67, 67, 0.1);
  }
}
</style>
