<template>
  <div id="trade-order-book-table">
    <div class="sm:tw-grid sm:tw-grid-cols-2 sm:tw-gap-x-2 lg:tw-gap-x-8">
      <div
        class="tw-bg-white tw-rounded tw-px-4 sm:tw-px-8 sm:tw-py-8 tw-py-4 tw-mb-6 sm:tw-mb-0"
      >
        <div>
          <h3 class="tw-text-primary">Sell Order</h3>
          <div
            v-if="!smallLoading"
            class="tw-grid tw-grid-cols-3 tw-gap-x-2 tw-mt-2"
          >
            <h3 class="tw-text-sm sm:tw-text-base tw-text-black-light">
              Price/Token
            </h3>
            <h3 class="tw-text-sm sm:tw-text-base tw-text-black-light">
              Tokens
            </h3>
            <h3 class="tw-text-sm sm:tw-text-base tw-text-black-light">
              Total
            </h3>
          </div>
          <div
            v-if="!smallLoading"
            class="tw-grid tw-grid-cols-3 tw-gap-x-2 tw-mt-2"
          >
            <ul>
              <li
                v-for="(order, index) in sellOrderBook"
                :key="'header' + index"
                class="tw-text-error tw-mb-1"
              >
                {{ formatAmountToDollar(Number(order?.price), 2, "USD") }}
              </li>
            </ul>
            <ul>
              <li
                v-for="(order, index) in sellOrderBook"
                :key="'header' + index"
                class="tw-flex tw-items-center tw-mb-1"
              >
                <img
                  src="@/assets/img/token-icon.svg"
                  class="tw-h-4 tw-mr-2"
                  alt="asset"
                />
                {{ convertToLocale(Number(order?.amount)) }}
              </li>
            </ul>
            <ul>
              <li
                v-for="(order, index) in sellOrderBook"
                :key="'header' + index"
                class="tw-mb-1"
              >
                {{
                  formatAmountToDollar(
                    Number(order?.amount * order?.price),
                    2,
                    "USD"
                  )
                }}
              </li>
            </ul>
          </div>
          <EmptyState
            v-if="sellOrderBook.length === 0 && !smallLoading"
            class="tw-mt-8"
          >
            <template v-slot:message>
              <p class="message tw-text-center tw-text-gray tw-my-3">
                There is no pending sell order
              </p>
            </template>
          </EmptyState>
          <SmallLoader v-if="smallLoading" />
        </div>
      </div>
      <div class="tw-bg-white tw-rounded tw-px-4 sm:tw-px-8 sm:tw-py-8 tw-py-4">
        <h3 class="tw-text-primary">Buy Order</h3>
        <div
          v-if="!smallLoading"
          class="tw-grid tw-grid-cols-3 tw-gap-x-2 tw-mt-2"
        >
          <h3 class="tw-text-sm sm:tw-text-base tw-text-black-light">
            Price/Token
          </h3>
          <h3 class="tw-text-sm sm:tw-text-base tw-text-black-light">Tokens</h3>
          <h3 class="tw-text-sm sm:tw-text-base tw-text-black-light">Total</h3>
        </div>
        <div
          v-if="!smallLoading"
          class="tw-grid tw-grid-cols-3 tw-gap-x-2 tw-mt-2"
        >
          <ul>
            <li
              v-for="(order, index) in buyOrderBook"
              :key="'header' + index"
              class="tw-text-success tw-mb-1"
            >
              {{ formatAmountToDollar(Number(order?.price), 2, "USD") }}
            </li>
          </ul>
          <ul>
            <li
              v-for="(order, index) in buyOrderBook"
              :key="'header' + index"
              class="tw-flex tw-items-center tw-mb-1"
            >
              <img
                src="@/assets/img/token-icon.svg"
                class="tw-h-4 tw-mr-2"
                alt="asset"
              />
              {{ convertToLocale(Number(order?.amount)) }}
            </li>
          </ul>
          <ul>
            <li
              v-for="(order, index) in buyOrderBook"
              :key="'header' + index"
              class="tw-mb-1"
            >
              {{
                formatAmountToDollar(
                  Number(order?.amount * order?.price),
                  2,
                  "USD"
                )
              }}
            </li>
          </ul>
        </div>
        <EmptyState
          v-if="buyOrderBook.length === 0 && !smallLoading"
          class="tw-mt-8"
        >
          <template v-slot:message>
            <p class="message tw-text-center tw-text-gray tw-my-3">
              There is no pending buy order
            </p>
          </template>
        </EmptyState>
        <SmallLoader v-if="smallLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { formatAmountToDollar, convertToLocale } from "@/utils/helpers";
import EmptyState from "@/components/notification/EmptyState.vue";

export default {
  name: "TradeOrderBookTable",

  components: {
    EmptyState,
  },

  computed: {
    ...mapState({
      smallLoading: (state) => state.smallLoading,
      tokenInfo: (state) => state.Asset.token,
      buyOrderBook: (state) => state.Asset.buyOrderBook,
      sellOrderBook: (state) => state.Asset.sellOrderBook,
    }),
  },

  mounted() {
    this.getDetails();
  },

  methods: {
    ...mapActions("Asset", ["getTokenOrders"]),

    formatAmountToDollar,
    convertToLocale,

    getDetails() {
      const id = this.tokenInfo._id;
      const buyPayload = {
        id,
        data: {
          page: 1,
          type: "Buy",
          limit: 10,
          status: "Active",
        },
      };
      this.getTokenOrders(buyPayload);
      const sellPayload = {
        id,
        data: {
          page: 1,
          type: "Sell",
          limit: 10,
          status: "Active",
        },
      };
      this.getTokenOrders(sellPayload);
    },
  },

  watch: {
    tokenInfo(newValue, oldValue) {
      deep: true;
      immediate: true;
      if (newValue !== oldValue && this.$route.name === "TokenOverview") {
        this.getDetails();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#trade-order-book-table {
  @include fadeIn;

  .table {
    .header {
      .header-row {
        .header-data {
          display: grid;
          column-gap: 2rem;
        }
      }
    }
    .table-body {
      .body-row {
        .body-data {
          .data {
            display: grid;
            column-gap: 2rem;
            font-size: 0.7rem;

            .btn {
              max-width: 5.4rem;
            }

            // Added with javascript
            .completed,
            .pending,
            .cancelled {
              text-align: left;
              padding: 0.5rem;
              border-radius: 5px;
            }
            .completed {
              color: $success;
              background-color: $success-light;
              max-width: 5.4rem;
            }
            .pending {
              color: $warning;
              background-color: $warning-light;
              max-width: 4.2rem;
            }
            .cancelled {
              color: $error;
              background-color: $error-light;
              max-width: 5rem;
            }
          }
        }
      }
    }
  }
}
</style>
