<template>
  <div
    id="progress-card"
    class="tw-relative tw-text-white tw-rounded-lg tw-ease-out tw-duration-500 tw-px-4 tw-py-8 lg:tw-px-12"
    :class="{
      'tw-bg-secondary': !token.completed_raise,
      'tw-bg-primary': token.completed_raise,
    }"
  >
    <div>
      <div class="tw-flex tw-justify-between">
        <div v-if="token.completed_raise && token.investment_type !== 'Debt'">
          <h3 class="tw-text-sm">Listing valuation</h3>
          <p class="tw-text-lg">
            {{ formatAmountToDollar(token.valuation, 2, "USD") }}
          </p>
        </div>
        <div v-if="token.investment_type !== 'Debt'">
          <h3 class="tw-text-sm">Current valuation</h3>
          <p class="tw-text-lg">
            {{
              token.completed_raise
                ? formatAmountToDollar(token.post_raise_valuation, 2, "USD")
                : formatAmountToDollar(token.valuation, 2, "USD")
            }}
          </p>
        </div>
      </div>
      <div v-if="!token.completed_raise">
        <p class="tw-text-right tw-mt-8">
          {{
            (((token.total_raised * 10) / token.raise_amount) * 100).toFixed(2)
          }}% complete
        </p>
        <div class="tw-flex tw-w-full tw-h-1.5 tw-bg-gray-dark tw-rounded">
          <div
            class="tw-bg-success tw-rounded tw-ease-out tw-duration-500"
            :style="{
              flexBasis:
                (
                  ((token.total_raised * 10) / token.raise_amount) *
                  100
                ).toFixed(2) + '%',
            }"
          ></div>
        </div>
      </div>
      <p v-if="!token.completed_raise" class="tw-text-lg tw-mt-4">
        <span class="tw-text-blue-pastel">
          {{ formatAmountToDollar(token.total_raised * 10, 2, "USD") }} /
        </span>
        {{ formatAmountToDollar(token.raise_amount, 2, "USD") }}
        raised
      </p>
      <p v-if="token.completed_raise" class="tw-text-lg tw-mt-20">
        Raise Complete({{ formatAmountToDollar(token.raise_amount, 2, "USD") }})
        🚀🚀
      </p>
    </div>
    <img
      class="tw-absolute tw-bottom-0 tw-right-1/3"
      src="@/assets/img/ellipse1.svg"
      alt="asset"
    />
    <img
      class="tw-absolute tw-top-0 tw-right-1/4"
      src="@/assets/img/ellipse2.svg"
      alt="asset"
    />
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { formatAmountToDollar } from "@/utils/helpers";

  export default {
    name: "ProgressCard",

    computed: {
      ...mapState({
        token: (state) => state.Asset.token,
      }),
    },

    methods: {
      formatAmountToDollar,
    },
  };
</script>

<style lang="scss" scoped></style>
