<template>
  <div id="memo-table" class="md:tw-flex">
    <div class="tw-relative tw-w-full tw-bg-white tw-rounded-lg tw-p-4">
      <div class="tw-relative">
        <TableHead
          :options="options"
          :tab="option"
          label="options"
          @emitOption="emitOption"
        />
      </div>
      <div class="tw-mt-12 sm:tw-px-8 tw-pb-8">
        <h3 class="tw-text-lg tw-font-semibold tw-mb-4">{{ option }}</h3>
        <p v-if="option === 'Overview'" v-html="asset.memo?.overview"></p>
        <p v-if="option === 'Problem'" v-html="asset.memo?.problem"></p>
        <p v-if="option === 'Solution'" v-html="asset.memo?.solution"></p>
        <p v-if="option === 'Traction'" v-html="asset.memo?.traction"></p>
        <p v-if="option === 'Team'" v-html="asset.memo?.team"></p>
        <div
          v-if="option === 'Supporting documents'"
          class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-3 tw-gap-6"
        >
          <div
            v-for="(document, index) in asset?.documents"
            :key="index"
            class="tw-text-center tw-rounded-lg"
          >
            <a
              class="tw-inline-block tw-w-full tw-bg-gray-light tw-py-8"
              :href="document.url"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                class="tw-mx-auto"
                src="https://res.cloudinary.com/djalafcj9/image/upload/v1642975118/getequityV2/investor/15444693381558096235_1_donsbb.svg"
                alt="Pitch Deck"
              />
              {{ document.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TableHead from "@/components/tables/TableHeader";

export default {
  name: "MemoTable",

  components: { TableHead },

  data() {
    return {
      options: [
        "Overview",
        "Problem",
        "Solution",
        "Traction",
        "Team",
        "Supporting documents",
      ],
      option: "",
    };
  },

  computed: {
    ...mapState({
      asset: (state) => state.Asset.asset,
    }),
  },

  methods: {
    emitOption(option) {
      switch (option) {
        case "Overview":
          this.option = "Overview";
          break;
        case "Problem":
          this.option = "Problem";
          break;
        case "Solution":
          this.option = "Solution";
          break;
        case "Traction":
          this.option = "Traction";
          break;
        case "Team":
          this.option = "Team";
          break;
        case "Supporting documents":
          this.option = "Supporting documents";
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
