<template>
  <div id="raise-table" class="md:tw-flex">
    <div class="tw-relative tw-w-full tw-bg-white tw-rounded-lg tw-p-4">
      <div class="tw-relative"></div>
      <div class="container tw-w-full tw-overflow-auto">
        <table class="table tw-w-full">
          <thead class="header">
            <tr class="header-row">
              <th
                class="header-data tw-font-normal tw-py-6"
                ref="tableHead"
                :style="gridStyling"
              >
                <div
                  class="tw-justify-self-start"
                  v-for="(header, index) in tableHeaders"
                  :key="'header' + index"
                >
                  {{ header }}
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr class="body-row">
              <td class="body-data tw-min-h-full" ref="tableRow">
                <div
                  class="data tw-border-t tw-border-gray-light hover:tw-bg-gray-lightest tw-cursor-pointer"
                  :class="{
                    'tw-py-4': $route.name === 'Invest',
                    'tw-py-6': $route.name !== 'Invest',
                  }"
                  v-for="(details, index) in tableRows"
                  :key="'row' + index"
                  :style="gridStyling"
                  @click="gotoTokenPage(details)"
                >
                  <div
                    class="tw-self-center"
                    v-for="(detail, index) in details"
                    :key="'column' + index"
                  >
                    <p
                      v-html="detail"
                      class="tw-text-sm"
                      :class="{
                        [detail.toLowerCase()]: availableStatuses.includes(
                          detail
                        ),
                      }"
                    ></p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <SmallLoader v-if="smallLoading && table === 'raisingTable'" />
      <div
        v-if="$route.name !== 'Invest'"
        class="tw-flex tw-justify-end tw-mr-2 tw-mb-8"
      >
        <Pagination
          :totalPage="pages"
          :currentPage="page"
          :maxVisible="5"
          @pageChange="gotoPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { formatAmountToDollar, capitalizeEachWord } from "@/utils/helpers.js";
import TableHead from "@/components/tables/TableHeader";
import Pagination from "@/components/navigation/Pagination";
import EmptyState from "@/components/notification/EmptyState.vue";

export default {
  name: "RaisingTable",

  components: { TableHead, Pagination, EmptyState },

  data() {
    return {
      tableHeaders: ["", "Company", "Amount to raise", "Price", "Progress"],
      tableRows: [],
      availableStatuses: ["Active", "Fulfilled", "Terminated"],
      table: "",
    };
  },

  computed: {
    ...mapState({
      smallLoading: (state) => state.smallLoading,
      companiesRaising: (state) => state.Asset.companiesRaising,
      page: (state) => state.Asset.raisingPage,
      pages: (state) => state.Asset.raisingPages,
    }),
    gridStyling() {
      return {
        "grid-template-columns": `${80}px ${200}px ${200}px ${100}px ${300}px`,
      };
    },
  },

  mounted() {
    this.getDetails();
  },

  methods: {
    ...mapActions("Asset", ["getCompaniesRaising"]),

    gotoTokenPage(details) {
      const id = details.id.split(">")[1].split("<")[0];
      const symbol = details.symbol.split(">")[1].split("<")[0];
      this.$router.push({ name: "TokenTrade", params: { symbol, id: id } });
    },

    async getDetails() {
      let limit;
      this.table = "raisingTable";
      this.$route.name === "Invest" ? (limit = 3) : (limit = 10);
      const payload = { page: 1, limit: limit };
      await this.getCompaniesRaising(payload);
      this.table = "";
    },

    async gotoPage(page) {
      this.table = "raisingTable";
      this.tableRows = [];
      const payload = {
        page: page,
        limit: 10,
      };
      await this.getCompaniesRaising(payload);
      this.table = "";
    },

    updateTableRow(tokens) {
      this.tableRows = tokens.map((token, index) => {
        const currentValue = token.total_raised * 10;
        const targetAmount = token.raise_amount;
        const progress = ((currentValue / targetAmount) * 100).toFixed(2);
        return {
          image: `<span class="tw-flex tw-ml-8"><img
                        src=${token.image}
                        class="tw-mr-8"
                        :alt="${token.name}"
                        loading="lazy"
                      /></span>`,
          company: `<span class="tw-font-bold">${capitalizeEachWord(
            token.name
          )}</span>
            <br /><span class="tw-text-black-light tw-text-xs">${
              token.symbol
            }</span>`,
          raise: formatAmountToDollar(token.raise_amount, 2, "USD"),
          price: formatAmountToDollar(token.price.sell, 2, "USD"),
          bar: `<div class="tw-grid tw-grid-cols-2 tw-gap-x-2 tw-items-center">
            <div class="tw-relative tw-overflow-hidden tw-bg-success tw-h-1.5 tw-rounded">
              <div
                class="tw-absolute tw-bg-gray-dark tw-w-full tw-h-1.5 tw-rounded"
                style="left: ${progress}%"
              ></div>
            </div><span class="tw-text-black-light tw-text-xs">${progress}%</span>
          </div>`,
          id: `<span class="tw-hidden">${token._id}</span>`,
          symbol: `<span class="tw-hidden">${token.symbol}</span>`,
        };
      });
    },
  },

  watch: {
    companiesRaising(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateTableRow(this.companiesRaising);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#raise-table {
  @include fadeIn;

  .container::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .table {
    .header {
      .header-row {
        .header-data {
          display: grid;
          column-gap: 2rem;
        }
      }
    }
    .table-body {
      .body-row {
        .body-data {
          .data {
            display: grid;
            column-gap: 2rem;
            font-size: 0.7rem;

            .gauge-container {
              .gauge {
                .fill {
                  top: 0;
                  transition: all 0.5s ease-out;
                }
              }
            }

            // Added with javascript
            .completed,
            .pending,
            .failed {
              text-align: left;
              padding: 0.5rem;
              border-radius: 5px;
            }
            .completed {
              color: $success;
              background-color: $success-light;
              max-width: 5.4rem;
            }
            .pending {
              color: $warning;
              background-color: $warning-light;
              max-width: 4.2rem;
            }
            .failed {
              color: $error;
              background-color: $error-light;
              max-width: 4.4rem;
            }
          }
        }
      }
    }
  }
}
</style>
