<template>
  <div id="trade-card">
    <div class="tw-bg-white tw-rounded-lg tw-p-4 md:tw-p-8 lg:tw-px-12">
      <div class="tw-flex tw-items-center tw-mb-4">
        <h3 class="tw-text-lg tw-font-semibold tw-mr-2">New In</h3>
        <img class="" src="@/assets/img/listing.svg" alt="invest card" />
      </div>
      <p class="tw-text-black-light">
        Join one of the first few to invest in the most promising ‘Fresh out of
        the box’ startups
      </p>
      <div
        class="tw-grid tw-grid-cols-3 lg:tw-grid-cols-5 tw-gap-4 tw-content-center tw-items-center tw-mt-8"
      >
        <div v-for="(token, index) in raising" :key="index">
          <div
            class="tw-w-full tw-flex tw-justify-center tw-bg-gray-light tw-rounded-lg tw-cursor-pointer tw-py-4 tw-px-1"
            @click="
              $router.push({
                name: 'TokenTrade',
                params: { symbol: token.symbol, id: token._id },
              })
            "
          >
            <img class="tw-w- tw-h-12" :src="token.image" :alt="token.name" />
          </div>
        </div>
      </div>
      <SmallLoader v-if="smallLoading && card === 'listingCard'" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ListingCard",

  data() {
    return {
      card: "",
      raising: [],
    };
  },

  computed: {
    ...mapState({
      smallLoading: (state) => state.smallLoading,
      companiesRaising: (state) => state.Asset.recentRaising,
    }),
  },

  mounted() {
    this.getDetails();
  },

  methods: {
    ...mapActions("Asset", ["getCompaniesRaising"]),

    async getDetails() {
      this.raising = [];
      this.card = "listingCard";
      const payload = { page: 1, limit: 10 };
      await this.getCompaniesRaising(payload);
      this.raising = [...this.companiesRaising];
      this.card = "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
