<template>
  <div
    id="invest-card"
    class="tw-bg-white tw-rounded-lg tw-px-4 lg:tw-px-12 tw-py-6"
  >
    <div v-if="header === 'cash'">
      <div
        class="lg:tw-grid lg:tw-grid-cols-2 lg:tw-gap-x-2 lg:tw-items-center"
      >
        <div class="tw-mb-8 lg:tw-mb-0">
          <h3 class="tw-text-black-light tw-mb-1">
            Available Cash
            <button class="hover:tw-bg-transparent" @click="visible = !visible">
              <img src="@/assets/img/eye-closed.svg" v-if="visible" />
              <img src="@/assets/img/eye.svg" v-else />
            </button>
          </h3>
          <p class="tw-text-2xl tw-font-bold">
            {{ walletBalanceFormatted }}
          </p>
        </div>
        <div class="tw-justify-self-end">
          <Btn
            title="+ Add to Wallet"
            :plus="true"
            @click="$emit('btnClicked')"
          />
        </div>
      </div>
    </div>
    <div v-if="header === 'token'">
      <ul
        v-if="tokens.length === 0"
        class="lg:tw-grid lg:tw-grid-cols-3 tw-items-center tw-gap-x-2"
      >
        <li class="tw-col-start-1 tw-col-end-3">
          <h3 class="tw-text-black-light tw-mb-4 lg:tw-mb-1">Your tokens</h3>
          <span class="tw-text-xl"> You have zero token.</span>
        </li>
        <Btn
          v-if="tokens.length === 0"
          class="tw-mt-6 lg:tw-mt-0 tw-col-start-3 tw-col-end-4"
          title="Buy Token"
          :plus="true"
          @click="$router.push({ name: 'Trade' })"
        />
      </ul>
      <h3 v-if="tokens.length !== 0" class="tw-text-black-light tw-mb-2">
        Your tokens
      </h3>
      <ul
        v-if="tokens.length !== 0"
        class="tw-grid tw-grid-cols-2 tw-items-center tw-gap-x-2"
      >
        <li class="tw-grid tw-grid-cols-4 tw-col-start-1 tw-col-end-4">
          <span
            v-for="(token, index) in tokens?.slice(-4)"
            :key="index"
            class=""
          >
            <img
              class="tw-h-10 tw-w-10 tw-rounded"
              :src="token.image"
              :alt="token.name"
            />
          </span>
        </li>
        <li class="tw-col-start-5 tw-col-end-6">
          <router-link
            v-if="tokens.length !== 0"
            class="tw-flex tw-text-primary tw-whitespace-nowrap"
            :to="{ name: 'Wallet', params: { option: 'tokens' } }"
          >
            See all <img src="@/assets/img/caret-right.svg" alt="caret" />
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  import { formatAmountToDollar } from "@/utils/helpers.js";
  import Btn from "@/components/general/BtnComponent.vue";
  import { computed, ref } from "vue";

  const { header, walletBalance, tokens } = defineProps({
    header: {
      type: String,
      required: true,
    },
    walletBalance: {
      type: Number,
      default: () => 0,
    },
    tokens: {
      type: Array,
      default: () => [],
    },
  });
  const visible = ref(false);
  const walletBalanceFormatted = computed(() => {
    return visible.value
      ? "*****"
      : formatAmountToDollar(walletBalance, 2, "USD");
  });
</script>

<style lang="scss" scoped></style>
