<template>
  <div id="invest">
    <div v-if="!loading" class="container tw-px-4 tw-mb-12">
      <div class="lg:tw-px-4 tw-mb-12">
        <h3 class="tw-text-2xl tw-font-bold tw-pb-1">
          Invest
        </h3>
        <div
          class="md:tw-grid md:tw-grid-cols-2 md:tw-gap-x-4 xl:tw-gap-x-8 tw-mt-4"
        >
          <InvestCard
            header="cash"
            :walletBalance="walletBalance"
            @btnClicked="showFundModal = !showFundModal"
          />
          <InvestCard
            header="token"
            :tokens="tokens"
            class="tw-mt-6 md:tw-mt-0"
          />
        </div>
      </div>
      <div class="lg:tw-px-4 tw-mb-12">
        <h3 class="tw-text-lg tw-font-bold">Companies still raising</h3>
        <p class="tw-text-black-light">Better late than never!</p>
        <div class="md:tw-grid md:tw-grid-cols-4 md:tw-gap-x-2 tw-min-h-max">
          <div class="md:tw-col-start-3 md:tw-col-end-4 tw-flex tw-justify-end">
            <router-link
              class="tw-flex tw-text-primary tw-whitespace-nowrap"
              :to="{ name: 'Trade' }"
            >
              See all
              <img src="@/assets/img/caret-right.svg" alt="caret" />
            </router-link>
          </div>
          <RaisingTable class="md:tw-col-start-1 md:tw-col-end-4 tw-mt-2" />
          <flickity v-if="!loading" ref="flickity" :options="flickityOptions">
            <div
              v-for="carousel in carousels"
              :key="carousel._id"
              class="tw-w-full tw-h-full"
            >
              <img
                class="tw-w-full tw-h-full tw-cursor-pointer tw-mt-2"
                :src="carousel.cta_url"
                alt="invest card"
                @click="carouselInfo(carousel)"
              />
            </div>
          </flickity>
        </div>
      </div>
      <div class="lg:tw-px-4 tw-mb-12">
        <h3 class="tw-text-lg tw-font-bold">Secondary Market</h3>
        <p class="tw-text-black-light">
          Trade tokens that have completed their rounds
        </p>
        <div class="tw-mt-4">
          <div class="tw-flex tw-justify-end tw-mb-2">
            <router-link
              class="tw-flex tw-text-primary tw-whitespace-nowrap"
              :to="{ name: 'Trade', params: { option: 'secondary' } }"
            >
              View all secondaries
              <img src="@/assets/img/caret-right.svg" alt="caret" />
            </router-link>
          </div>
          <RaisedCard />
        </div>
      </div>
      <div class="lg:tw-px-4">
        <h3 class="tw-text-lg tw-font-bold">Token Orders</h3>
        <div class="tw-mt-4">
          <div class="tw-flex tw-justify-end tw-mb-2">
            <router-link
              class="tw-flex tw-text-primary tw-whitespace-nowrap"
              :to="{ name: 'InvestOrderbook' }"
            >
              View all orders
              <img src="@/assets/img/caret-right.svg" alt="caret" />
            </router-link>
          </div>
          <div class="tw-bg-white tw-rounded-lg tw-px-4 tw-py-6">
            <OrderBookTable />
          </div>
        </div>
      </div>
    </div>
    <Loader v-if="loading" />
    <FundModal v-if="showFundModal" @close-modal="closeModal" />
    <InvestModal
      v-if="showInvestModal"
      @close="showInvestModal = !showInvestModal"
    >
      <template v-slot:content>
        <InvestTogether />
      </template>
    </InvestModal>
  </div>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import { getItem, removeItem } from "@/utils/storage.js";
  import InvestCard from "@/components/invest/InvestCard";
  import InvestTogether from "@/components/invest/InvestTogether";
  import RaisingTable from "@/components/tables/RaisingTable";
  import RaisedCard from "@/components/invest/RaisedCard";
  import OrderBookTable from "@/components/tables/OrderBookTable";
  // import FundModal from "@/components/general/sidemodal/SideModal";
  import FundModal from "@/components/wallet/fund/FundModal.vue";

  import InvestModal from "@/components/general/MediumModal";
  import FundOptionsForm from "@/components/wallet/fund/FundOptionsForm";
  import CardOptionsForm from "@/components/wallet/fund/CardOptionsForm";
  import FundAmountForm from "@/components/wallet/fund/FundAmountForm";
  import FundConfirmationForm from "@/components/wallet/fund/FundConfirmationForm";
  import SelectCurrencyForm from "@/components/wallet/fund/SelectCurrencyForm";
  import DedicatedVirtualAccountForm from "@/components/wallet/fund/DedicatedVirtualAccountForm";
  import countryList from "@/utils/js/countryRegionData.js";
  import Flickity from "vue-flickity";
  import { useFundNotification } from "@/composables/notifyUserFunding";

  export default {
    name: "Invest",

    components: {
      InvestCard,
      RaisingTable,
      RaisedCard,
      FundModal,
      OrderBookTable,
      FundOptionsForm,
      FundConfirmationForm,
      FundAmountForm,
      CardOptionsForm,
      SelectCurrencyForm,
      DedicatedVirtualAccountForm,
      InvestModal,
      Flickity,
      InvestTogether,
    },

    data() {
      return {
        loading: false,
        showFundModal: false,
        isAllowed: true,
        showInvestModal: false,
        fundSubTitle: "",
        fundForm: "",
        withdrawFees: 0,
        countryList,
        flickityOptions: {
          initialIndex: 0,
          prevNextButtons: false,
          pageDots: true,
          wrapAround: true,
          autoPlay: true,
        },
      };
    },
    setup() {
      const { checkFundingStatus } = useFundNotification();
      return { checkFundingStatus };
    },

    computed: {
      ...mapState({
        smallLoading: (state) => state.smallLoading,
        carousels: (state) => state.carousel,
        exchangeRate: (state) => state.organisationModule.exchangeRate,
        walletBalance: (state) => state.organisationModule.tradeBalance,
        entityCard: (state) => state.organisationModule.entityCard,
        tokens: (state) => state.Asset.tokens,
        fundMethod: (state) => state.organisationModule.fundMethod,
        currency: (state) => state.organisationModule.currency,
      }),
      nigerianStates() {
        return this.countryList.find(
          (country) => country.countryName === "Nigeria"
        ).regions;
      },
    },

    created() {
      this.getPageDetails();
      // this.getUserLocation();
    },

    mounted() {
      removeItem("fundPayload");
      this.checkFundingStatus();
    },

    methods: {
      ...mapActions("organisationModule", [
        "getWalletBalance",
        "generateVirtualAccount",
        "getEntityCard",
      ]),
      ...mapActions("Asset", ["getTokens"]),
      ...mapActions(["showToast", "getCarousel"]),

      getUserLocation() {
        const country = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.nigerianStates.map((state) => {
          if (country.includes(state.name)) {
            this.isAllowed = false;
          }
        });
      },

      async getPageDetails() {
        this.loading = true;
        let walletBalance = this.getWalletBalance();
        const query = { page: 1, limit: 10 };
        let tokens = this.getTokens(query);
        let entityCards = this.getEntityCard();
        let carousel = this.getCarousel();
        await Promise.all([walletBalance, tokens, entityCards, carousel]);
        this.loading = false;
      },

      async calculateFees() {
        const currency = getItem("withdrawalPayload").currency;
        const amount = getItem("withdrawalPayload").amount;
        let fee;
        const response = await api.withdrawFees();
        const { data } = response.data;
        switch (currency) {
          case "NGN":
            this.withdrawFees = data.NGN;
            break;
          case "USD":
            fee = (1 / 100) * amount;
            if (fee > data.USD_MIN) {
              this.withdrawFees = data.USD_MIN;
            } else {
              this.withdrawFees = fee;
            }
            break;
          case "EUR":
            fee = (1 / 100) * amount;
            if (fee > data.USD_MIN) {
              this.withdrawFees = data.USD_MIN;
            } else {
              this.withdrawFees = fee;
            }
            break;
          case "":
            this.withdrawFees = 0;
            break;

          default:
            break;
        }
      },

      async switchWithdrawForm(form) {
        switch (form) {
          case "amountForm":
            this.withdrawForm = "withdrawOptionsForm";
            this.subTitle = "Withdraw cash to";
            break;
          case "withdrawOptionsForm":
            await this.calculateFees();
            this.withdrawForm = "confirmationForm";
            this.subTitle = "Confirmation";
            break;
          case "selectCountryForm":
            this.withdrawForm = "withdrawOptionsForm";
            this.subTitle = "Withdraw cash to";
            break;
          case "Trading Wallet":
            await this.calculateFees();
            this.withdrawForm = "confirmationForm";
            this.subTitle = "Confirmation";
            break;
          case "Add Bank":
            this.withdrawForm = "selectCountryForm";
            this.subTitle = "Add Bank";
            break;
          case "Fund with Cash":
            this.fundForm = "SelectCurrencyForm";
            this.fundSubTitle = "Select your preferred currency.";
            this.fundOptions = "Fund with Cash";
            break;
          case "selectCurrencyForm":
            const payload = {
              currency: this.currency,
              provider: "flutterwave",
            };
            if (this.fundMethod === "virtual") {
              const response = await this.generateVirtualAccount(payload);
              if (response) {
                this.fundForm = "DedicatedVirtualAccountForm";
                this.fundSubTitle =
                  this.currency === "NGN"
                    ? "Make a direct transfer to this account to fund your wallet. Transactions may take 24 hours to complete."
                    : "Make a direct transfer to this account to fund your wallet. Transactions may take 3-5 business days to complete.";
              }
            }
            if (this.fundMethod === "fiat" || this.fundMethod === "crypto") {
              this.fundForm = "fundAmountForm";
              this.fundSubTitle = "How much do you want to fund?";
            }
            if (this.fundOptions === "Fund with Fintech Apps") {
              this.fundOptions = "Fund with Fintech Apps";
            }
            if (this.fundOptions === "Fund with Cash") {
              this.fundOptions = "Fund with Cash";
            }
            if (this.fundOptions === "Fund with Fluidcoins") {
              this.fundOptions = "Fund with Fluidcoins";
            }
            break;
          case "DedicatedVirtualAccountForm":
            this.fundForm = "DedicatedVirtualAccountForm";
            this.fundSubTitle =
              this.currency === "NGN"
                ? "Make a direct transfer to this account to fund your wallet. Transactions may take 24 hours to complete."
                : "Make a direct transfer to this account to fund your wallet. Transactions may take 3-5 business days to complete."; // this.fundOptions = "Fund with Cash";
            break;
          case "Fund with Fintech Apps":
            this.fundForm = "SelectCurrencyForm";
            this.fundSubTitle = "Select your preferred currency.";
            this.fundOptions = "Fund with Fintech Apps";
            break;
          case "Fund with Fluidcoins":
            this.fundForm = "SelectCurrencyForm";
            this.fundSubTitle = "Select your preferred currency.";
            this.fundOptions = "Fund with Fluidcoins";
            break;
          case "fundAmountForm":
            if (this.entityCard.length === 0) {
              this.fundForm = "fundConfirmationForm";
              this.fundSubTitle = "Payment Confirmation";
            } else {
              this.fundForm = "CardOptions";
              this.fundSubTitle = "Select Debit Card";
            }
            break;
          case "CardOptions":
            this.fundForm = "fundConfirmationForm";
            this.fundSubTitle = "Payment Confirmation";
            break;
          case "storedCard":
            this.fundForm = "fundConfirmationForm";
            this.fundSubTitle = "Payment Confirmation";
            this.fundOptions = "Fund with stored card";
            break;
          case "newCard":
            this.fundForm = "fundConfirmationForm";
            this.fundSubTitle = "Payment Confirmation";
            this.fundOptions = "Fund with Cash";
            break;

          default:
            break;
        }
      },

      goBack(form) {
        switch (form) {
          case "withdrawOptionsForm":
            this.withdrawForm = "amountForm";
            this.subTitle = "How much do you want to withdraw?";
            break;
          case "confirmationForm":
            this.withdrawForm = "withdrawOptionsForm";
            this.subTitle = "Withdraw cash to";
            break;
          case "selectCountryForm":
            this.withdrawForm = "withdrawOptionsForm";
            this.subTitle = "Withdraw cash to";
            break;
          case "SelectCurrencyForm":
            this.fundForm = "fundOptionsForm";
            this.fundSubTitle = "How do you want to fund your wallet";
            break;
          case "fundAmountForm":
            // if (this.fundOptions === "Fund with Fintech Apps") {
            //   this.fundForm = "fundOptionsForm";
            //   this.fundSubTitle = "How do you want to fund your wallet";
            // } else {
            this.fundForm = "SelectCurrencyForm";
            this.fundSubTitle = "Select your preferred currency.";
            // }
            break;
          case "DedicatedVirtualAccountForm":
            this.fundForm = "SelectCurrencyForm";
            this.fundSubTitle = "Select your preferred currency.";
            break;
          case "fundConfirmationForm":
            if (this.entityCard.length === 0) {
              this.fundForm = "fundAmountForm";
              this.fundSubTitle = "How much do you want to fund?";
            } else {
              this.fundForm = "CardOptions";
              this.fundSubTitle = "Select Debit Card";
            }
            break;
          case "CardOptions":
            this.fundForm = "fundAmountForm";
            this.fundSubTitle = "How much do you want to fund?";
            break;

          default:
            break;
        }
      },

      refresh(modal) {
        switch (modal) {
          case "Fund Wallet":
            this.fundForm = "fundOptionsForm";
            this.fundSubTitle = "How do you want to fund your wallet";
            break;
          case "Withdraw Cash":
            this.withdrawForm = "amountForm";
            this.subTitle = "How much do you want to withdraw?";
            break;

          default:
            break;
        }
      },

      closeModal(modal) {
        switch (modal) {
          case "Withdraw Cash":
            this.showWithdrawModal = !this.showWithdrawModal;
            removeItem("withdrawalPayload");
            break;
          case "Fund Wallet":
            this.showFundModal = !this.showFundModal;
            removeItem("fundPayload");
            break;

          default:
            break;
        }
      },

      carouselInfo(carousel) {
        window.open(
          carousel.cta_text,
          "_blank" // <- This is what makes it open in a new window.
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  #invest {
    width: 100%;
    overflow: hidden;

    .container {
      @include fadeIn;

      @media screen and (min-width: 1024px) {
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .table-container {
      overflow: auto;
    }

    .message {
      max-width: 250px;
    }
  }
</style>
<style lang="scss">
  .flickity-viewport {
    height: 400px !important;
    border-radius: 0.5rem;

    @media screen and (min-width: 768px) {
      height: 100% !important;
    }
  }
</style>
