<template>
  <div id="listing-token-amount-form">
    <div
      class="tw-w-48 tw-text-sm tw-text-center tw-rounded tw-bg-gray-bg3 tw-mx-auto tw-mt-4 tw-p-1"
    >
      <p class="">Available Cash</p>
      <p>{{ formatAmountToDollar(tradeBalance, 2, "USD") }}</p>
    </div>
    <form class="tw-w-full tw-mt-8" @submit.prevent="showModal">
      <div class="tw-grid tw-grid-cols-2 tw-items-center">
        <div>
          <p class="tw-font-bold tw-text-secondary">How many tokens?</p>
          <p class="tw-text-sm tw-text-gray tw-mb-1">
            Price:
            {{ formatAmountToDollar(tokenInfo.price?.buy, 2, "USD") }}/token
          </p>
        </div>
        <NumberInput
          placeHolder="Token Amount"
          :amount="payload.tokens"
          :maxNumber="availableTokens"
          :checkValidity="tokenValid"
          type="text"
          fieldType="tokenAmount"
          name="tokenAmount"
          id="tokenAmount"
          @set="setTokenAmount"
          @valid="tokenAmountValid"
          @validate="validate"
        />
      </div>
      <img
        class="tw-mx-auto tw-my-4"
        src="@/assets/img/convert-icon.svg"
        alt="plus"
      />
      <div class="tw-grid tw-grid-cols-2 tw-items-center">
        <div>
          <p class="tw-font-bold tw-text-secondary">
            Dollar Amount(USD)
          </p>
          <p class="tw-text-sm tw-text-gray tw-mb-1">
            Min. ~ {{ formatAmountToDollar(minAmount, 2, "USD") }}
          </p>
        </div>
        <NumberInput
          placeHolder="Dollar Amount"
          :amount="payload.dollarAmount"
          :minNumber="minAmount"
          :maxNumber="tradeBalance"
          :checkValidity="dollarValid"
          :disabled="true"
          type="text"
          fieldType="dollarAmount"
          name="dollarAmount"
          id="dollarAmount"
          @set="setDollarAmount"
          @valid="dollarAmountValid"
          @validate="validate"
        />
        <p class="tw-col-start-2 tw-col-end-3 tw-text-gray tw-mt-4">
          + Transaction fee: {{ tokenInfo.buy_fee }}%
        </p>
      </div>
      <div class="tw-rounded tw-bg-gray-light tw-py-4 tw-mt-8 tw-mb-6">
        <p class="tw-text-center tw-w-full tw-text-gray tw-mb-1">
          You pay:
        </p>
        <p class="tw-text-2xl tw-text-secondary tw-text-center">
          {{
            formatAmountToDollar(
              Number(totalAmount) +
                (Number(payload.dollarAmount) * tokenInfo.buy_fee) / 100,
              2,
              "USD"
            )
          }}
        </p>
      </div>
      <Btn title="Buy" />
    </form>
    <SmallModal
      v-if="showConfirmModal"
      class="tw-z-50"
      @close="showConfirmModal = !showConfirmModal"
    >
      <template v-slot:content>
        <h3 class="tw-text-xl tw-text-center tw-font-bold">Confirmation</h3>
        <div class="tw-bg-gray-light tw-rounded tw-px-4 tw-py-6 tw-mt-6">
          <div class="tw-flex tw-justify-between tw-items-center">
            <div>
              <p class="tw-text-gray">You are buying</p>
              <h3 class="tw-text-">
                {{ payload.tokens }} {{ tokenInfo.name }} Token(s)
              </h3>
            </div>
            <img
              class="tw-h-10 tw-rounded"
              :src="tokenInfo.image"
              :alt="tokenInfo.name"
            />
          </div>
          <div class="tw-flex tw-justify-between tw-items-center tw-mt-6">
            <div>
              <p class="tw-text-gray">For</p>
              <h3 class="tw-text-">
                {{
                  formatAmountToDollar(Number(payload.dollarAmount), 2, "USD")
                }}
              </h3>
            </div>
            <div>
              <p class="tw-text-gray">Transaction Fee</p>
              <div class="tw-flex tw-justify-between">
                <span class="tw-text- tw-text-right">
                  {{
                    formatAmountToDollar(
                      (Number(payload.dollarAmount) * tokenInfo.buy_fee) / 100,
                      2,
                      "USD"
                    )
                  }}
                </span>
                <span class="tw-text-right ">({{ tokenInfo.buy_fee }}%)</span>
              </div>
            </div>
          </div>
        </div>
        <PinConfirmation class="tw-mt-2" btnTitle="Buy" @confirmed="buyToken" />
      </template>
    </SmallModal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { formatAmountToDollar } from "@/utils/helpers";
import NumberInput from "@/components/wallet/NumberInput.vue";
import SmallModal from "@/components/general/SmallModal.vue";
import PinConfirmation from "@/components/general/PinConfirmation.vue";
import Btn from "@/components/general/BtnComponent.vue";

export default {
  name: "ListingTokenAmountForm",

  emits: ["completed"],

  components: { NumberInput, SmallModal, PinConfirmation, Btn },

  data() {
    return {
      validInput: {
        dollarAmount: false,
        tokenAmount: false,
      },
      dollarValid: false,
      tokenValid: false,
      showConfirmModal: false,
      payload: { tokens: "", dollarAmount: "" },
      totalAmount: "",
      availableTokens: 0,
      minAmount: 10,
    };
  },

  computed: {
    ...mapState({
      tradeBalance: (state) => state.organisationModule.tradeBalance,
      tokenInfo: (state) => state.Asset.token,
    }),
  },

  mounted() {
    this.calculateDetails();
  },

  methods: {
    ...mapActions("organisationModule", ["tradeToken"]),
    ...mapActions(["showToast"]),

    formatAmountToDollar,

    async buyToken() {
      const payload = {
        id: this.tokenInfo._id,
        amount: Number(this.payload.tokens),
        type: "buy",
      };
      const response = await this.tradeToken(payload);
      if (response) {
        this.$emit("completed");
      }
    },

    calculateDetails() {
      this.availableTokens =
        this.tokenInfo.raise_amount / this.minAmount -
        this.tokenInfo.total_raised;
    },

    showModal() {
      this.dollarValid = true;
      this.tokenValid = true;
      setTimeout(() => {
        if (!this.validInput.dollarAmount || !this.validInput.tokenAmount) {
          return;
        }
        this.showConfirmModal = !this.showConfirmModal;
      }, 500);
    },

    setTokenAmount(amount) {
      this.payload.tokens = amount;
      this.payload.dollarAmount = String(amount * this.tokenInfo.price.buy);
      this.totalAmount = amount * this.tokenInfo.price.buy;
    },

    setDollarAmount(amount) {
      this.payload.dollarAmount = amount;
      this.payload.tokens = String(amount / this.tokenInfo.price.buy);
      this.totalAmount = amount;
    },

    dollarAmountValid(valid) {
      this.validInput.dollarAmount = valid;
    },

    tokenAmountValid(valid) {
      this.validInput.tokenAmount = valid;
    },

    validate() {
      this.dollarValid = false;
      this.tokenValid = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
