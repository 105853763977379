<template>
  <div id="token-trade-layout">
    <div v-if="!loading" class="container tw-px-4 tw-pb-12">
      <div class="tw-flex tw-justify-between lg:tw-px-4 tw-mb-4">
        <div class="sm:tw-flex">
          <BackBtn class="tw-hidden sm:tw-block tw-mr-6" />
          <div>
            <h3
              class="tw-flex tw-items-center tw-text-lg tw-font-semibold tw-mb-4"
            >
              <img
                :src="token.image"
                class="tw-h-8 tw-rounded tw-mr-2"
                :alt="token.name"
              />
              {{ capitalizeEachWord(token.name) }}
            </h3>
            <div
              class="tw-flex tw-items-center tw-font-semibold tw-text-black-light tw-mb-6"
            >
              <div class="tw-flex tw-items-center">
                <img
                  src="@/assets/img/asset.svg"
                  class="tw-h-8 tw-mr-2"
                  alt="asset"
                />
                <span>{{ tokenBalance.balance }}</span>
              </div>
              <span
                class="tw-bg-success-lightest tw-text-success tw-text-sm tw-rounded-2xl tw-py-1 tw-px-4 tw-ml-4"
              >
                {{ token.percentage >= 0 ? "+" : "-" }}
                {{ token.percentage }}%</span
              >
            </div>
          </div>
        </div>
        <div class="tw-w-32 tw-mt-12 sm:tw-mt-0">
          <Btn
            v-if="!token.completed_raise"
            title="Invest"
            @click="openInvestModal('invest')"
          />
          <Btn
            v-if="token.completed_raise"
            title="Trade Token"
            @click="openInvestModal('trade token')"
          />
        </div>
      </div>
      <div class="lg:tw-px-4">
        <ProgressCard />
        <a
          class="tw-inline-block tw-text-primary tw-mt-2"
          :href="asset?.website"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            class="tw-inline-block"
            src="@/assets/img/link.svg"
            alt="link icon"
          />
          {{ asset?.website }}
        </a>
      </div>
      <div
        v-if="token.completed_raise && token.secondaries"
        class="lg:tw-px-4 tw-my-12"
      >
        <h3 class="tw-font-bold">Order Book</h3>
        <div class="sm:tw-flex tw-text-black-light">
          <p>
            Last traded at
            {{ formatAmountToDollar(token.price.exchange, 2, "USD") }}/Token.
          </p>
        </div>
        <TradeOrderBookTable class="tw-mt-2" />
      </div>
      <div class="lg:tw-px-4 tw-mt-8">
        <MemoTable />
      </div>
      <div class="lg:tw-px-4 tw-mt-8">
        <CompanyUpdateCard />
      </div>
    </div>
    <Loader v-if="loading" />
    <InvestModal
      v-if="showInvestModal"
      :title="modalTitle"
      :subTitle="investSubTitle"
      :form="investForm"
      :bgChange="bgChange"
      @close="closeModal"
    >
      <template v-slot:content>
        <ListingTokenAmountForm
          v-if="investForm === 'ListingTokenAmountForm'"
          class="tw-px-4 lg:tw-px-10"
          @completed="completed"
        />
        <SecondariesTokenAmountForm
          v-if="investForm === 'SecondariesTokenAmountForm'"
          @completed="completed"
        />
      </template>
    </InvestModal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ProgressCard from "@/components/trade/ProgressCard.vue";
import MemoTable from "@/components/tables/MemoTable.vue";
import CompanyUpdateCard from "@/components/trade/CompanyUpdateCard.vue";
import ListingTokenAmountForm from "@/components/trade/forms/ListingTokenAmountForm.vue";
import SecondariesTokenAmountForm from "@/components/trade/forms/SecondariesTokenAmountForm.vue";
import TradeOrderBookTable from "@/components/tables/TradeOrderBookTable";
import InvestModal from "@/components/general/sidemodal/SideModal.vue";
import Btn from "@/components/general/BtnComponent.vue";
import { formatAmountToDollar, capitalizeEachWord } from "@/utils/helpers";
import responseHandler from "@/utils/responseHandler.js";
import BackBtn from "@/components/general/BackBtn";

export default {
  name: "TokenTrade",

  components: {
    ProgressCard,
    MemoTable,
    CompanyUpdateCard,
    InvestModal,
    ListingTokenAmountForm,
    SecondariesTokenAmountForm,
    TradeOrderBookTable,
    BackBtn,
    Btn,
  },

  data() {
    return {
      loading: false,
      showInvestModal: false,
      bgChange: false,
      modalTitle: "",
      investForm: "",
      investSubTitle: "",
    };
  },

  computed: {
    ...mapState({
      smallLoading: (state) => state.smallLoading,
      token: (state) => state.Asset.token,
      asset: (state) => state.Asset.asset,
      tokenBalance: (state) => state.Asset.singleTokenBalance,
    }),
  },

  created() {
    this.getTradeDetails();
  },

  methods: {
    ...mapActions("Asset", ["getSingleToken", "getTokenBalance", "fetchAsset"]),
    ...mapActions("organisationModule", ["getWalletBalance"]),

    capitalizeEachWord,
    formatAmountToDollar,

    completed() {
      this.showInvestModal = !this.showInvestModal;
      this.getTradeDetails();
    },

    async getTradeDetails() {
      this.loading = !this.loading;
      const id = this.$route.params.id;
      const symbol = this.$route.params.symbol;
      let singleToken = this.getSingleToken(id);
      let tokenBalance = this.getTokenBalance(symbol);
      let walletBalance = this.getWalletBalance();
      try {
        await Promise.all([singleToken, tokenBalance, walletBalance]);
        await this.fetchAsset(this.token.asset);
        this.loading = !this.loading;
      } catch (error) {
        responseHandler.handleError(error);
        return error;
      }
    },

    closeModal(modal) {
      switch (modal) {
        case `Invest in ${this.token.name}`:
          this.showInvestModal = !this.showInvestModal;
          break;

        default:
          break;
      }
    },

    openInvestModal(btn) {
      this.showInvestModal = !this.showInvestModal;
      switch (btn) {
        case "invest":
          this.modalTitle = `Invest in ${this.token.name}`;
          this.investForm = "ListingTokenAmountForm";
          this.bgChange = false;
          break;
        case "trade token":
          this.modalTitle = `Invest in ${this.token.name}`;
          this.investForm = "SecondariesTokenAmountForm";
          this.bgChange = true;
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#token-trade-layout {
  width: 100%;
  overflow: hidden;

  .container {
    @include fadeIn;

    @media screen and (min-width: 1024px) {
      max-width: 1280px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .table-container {
    overflow: auto;
  }

  .message {
    max-width: 250px;
  }
}
</style>
