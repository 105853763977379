<template>
  <div
    id="company-update-card"
    class="tw-bg-white tw-rounded-lg tw-p-4 sm:tw-p-12"
  >
    <h3 class="tw-text-lg tw-font-semibold tw-mb-8">Company Updates</h3>
    <div v-if="!loading">
      <div
        v-for="(update, index) in companyUpdate"
        :key="index"
        class="tw-relative tw-mb-12"
      >
        <img
          class="tw-absolute tw-h-8 tw-w-8 tw-rounded-full tw-mr-4"
          :src="token.image"
          alt="Pitch Deck"
        />
        <div class="tw-border-l tw-pl-12 tw-ml-4">
          <div
            class="md:tw-flex md:tw-items-center md:tw-justify-between tw-mb-4"
          >
            <h3 class="tw-text-lg tw-font-semibold">
              {{ update.title }}
            </h3>
            <span class="tw-text-sm tw-text-end tw-text-black-light">
              <span v-if="calculateDay(update.updatedAt) >= 1" class="tw-mr-1">
                {{ calculateDay(update.updatedAt) }} days
              </span>
              <span
                v-if="
                  calculateHour(update.updatedAt) >= 1 &&
                    calculateDay(update.updatedAt) < 1
                "
                class="tw-mr-1"
              >
                {{ calculateHour(update.updatedAt) }} hr
              </span>
              <span
                v-if="
                  calculateMin(update.updatedAt) < 59 &&
                    calculateHour(update.updatedAt) < 1 &&
                    calculateDay(update.updatedAt) < 1
                "
              >
                {{ calculateMin(update.updatedAt) }} min
              </span>
              ago
            </span>
          </div>
          <p v-html="truncate(update.body, 200)"></p>

          <a
            class="tw-inline-block tw-text-primary"
            :href="update.link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more
          </a>
        </div>
      </div>
    </div>
    <SmallLoader v-if="loading" class="tw-pt-24" />
    <EmptyState v-if="companyUpdate.length === 0">
      <template v-slot:message>
        <p class="message tw-text-center tw-text-gray tw-my-3">
          This company has no update.
        </p>
      </template>
    </EmptyState>
    <Pagination
      :totalPage="pages"
      :currentPage="page"
      :maxVisible="5"
      @pageChange="gotoPage"
    />
  </div>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import Pagination from "@/components/navigation/Pagination";
  import EmptyState from "@/components/notification/EmptyState.vue";
  import {
    truncate,
    calculateDay,
    calculateHour,
    calculateMin,
  } from "@/utils/helpers.js";

  export default {
    name: "CompanyUpdateCard",

    components: { Pagination, EmptyState },

    data() {
      return {
        loading: false,
      };
    },

    computed: {
      ...mapState({
        token: (state) => state.Asset.token,
        companyUpdate: (state) => state.Asset.companyUpdate,
        page: (state) => state.Asset.companyUpdatePage,
        pages: (state) => state.Asset.companyUpdatePages,
      }),
    },

    created() {
      this.getUpdate();
    },

    methods: {
      ...mapActions("Asset", ["getTokenUpdate"]),

      truncate,
      calculateDay,
      calculateHour,
      calculateMin,

      async getUpdate() {
        this.loading = !this.loading;
        const payload = { id: this.token._id, limit: 3, page: 1 };
        await this.getTokenUpdate(payload);
        this.loading = !this.loading;
      },

      async gotoPage(page) {
        this.loading = !this.loading;
        const payload = {
          id: this.token._id,
          page: page,
          limit: 3,
        };
        await this.getTokenUpdate(payload);
        this.loading = !this.loading;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
