<template>
  <div id="secondaries-token-amount-form">
    <div
      class="tw-flex tw-justify-between tw-items-center tw-bg-blue-pastel tw-px-4 lg:tw-px-10 tw-py-4"
    >
      <div>
        <h3 class="tw-text-lg tw-font-semibold">Switch to advanced mode</h3>
        <p class="tw-teext-sm">For more trading power and flexibility</p>
      </div>
      <ToggleButton @value-selected="toggleMode" />
    </div>
    <nav
      class="trade__nav tw-flex tw-justify-center tw-border-b tw-mb-4 tw-gap-12"
    >
      <button
        class="trade__button hover:tw-text-secondary hover:tw-bg-white tw-border-b-2 tw-border-white hover:tw-border-primary tw-text-gray tw-font-medium tw-py-4"
        :class="{ 'tw-border-primary tw-text-secondary': tradeType === 'Buy' }"
        @click="switchTradeType('Buy')"
      >
        Buy Token
      </button>
      <button
        class="trade__button hover:tw-text-secondary tw-bg-white hover:tw-bg-white tw-border-b-2 tw-border-white hover:tw-border-primary tw-text-gray tw-font-medium tw-py-4"
        :class="{
          'tw-border-primary tw-text-secondary': tradeType === 'Sell',
        }"
        @click="switchTradeType('Sell')"
        :disabled="singleTokenBalance <= 0"
      >
        Sell Token
      </button>
    </nav>
    <div class="tw-px-4 lg:tw-px-10">
      <div
        class="tw-w-48 tw-text-sm tw-text-center tw-rounded tw-bg-gray-bg3 tw-mx-auto tw-mt-4 tw-p-1"
      >
        <div v-if="tradeType === 'Buy'">
          <p class="">Available Cash</p>
          <p>{{ formatAmountToDollar(tradeBalance, 2, "USD") }}</p>
        </div>
        <div v-if="tradeType === 'Sell'">
          <p class="">Available {{ tokenInfo.symbol }} Token</p>
          <p>{{ singleTokenBalance }}</p>
        </div>
      </div>
    </div>
    <form
      class="tw-w-full tw-px-4 lg:tw-px-10 tw-mt-4"
      @submit.prevent="showModal"
    >
      <div class="tw-grid tw-grid-cols-2 tw-items-center">
        <div>
          <p class="tw-font-bold tw-text-secondary">
            How many tokens?
          </p>
          <p v-if="!advancedMode" class="tw-text-sm tw-text-gray tw-mb-1">
            Last traded at:
            {{
              formatAmountToDollar(tokenInfo.price?.exchange, 2, "USD")
            }}/token
          </p>
        </div>
        <NumberInput
          placeHolder="Token Amount"
          :amount="payload.tokens"
          :maxNumber="
            tradeType === 'Sell' ? singleTokenBalance : availableTokens
          "
          :checkValidity="tokenValid"
          type="text"
          fieldType="tokenAmount"
          name="tokenAmount"
          id="tokenAmount"
          @set="setTokenAmount"
          @valid="tokenAmountValid"
          @validate="validate"
        />
      </div>
      <img
        class="tw-mx-auto tw-my-4"
        src="@/assets/img/convert-icon.svg"
        alt="plus"
      />
      <div class="tw-grid tw-grid-cols-2 tw-items-center">
        <div>
          <p class="tw-font-bold tw-text-secondary">
            {{
              !advancedMode
                ? `Dollar Amount(USD)`
                : `Set ${tradeType} Price(USD)`
            }}
          </p>
          <p class="tw-text-sm tw-text-gray tw-mb-1">
            {{
              !advancedMode
                ? `Min. ~  ${formatAmountToDollar(minAmount, 2, "USD")}`
                : `Dollar amount per token`
            }}
          </p>
        </div>
        <NumberInput
          placeHolder="Dollar Amount"
          :amount="payload.dollarAmount"
          :minNumber="advancedMode ? null : minAmount"
          :maxNumber="maxNumber"
          :checkValidity="dollarValid"
          :disabled="!advancedMode"
          type="text"
          fieldType="dollarAmount"
          name="dollarAmount"
          id="dollarAmount"
          @set="setDollarAmount"
          @valid="dollarAmountValid"
          @validate="validate"
        />
        <p class="tw-col-start-2 tw-col-end-3 tw-text-gray tw-mt-4">
          + Transaction fee: {{ tokenInfo.buy_fee }}%
        </p>
      </div>
      <div
        v-if="tradeType === 'Buy'"
        class="tw-relative tw-rounded tw-border tw-border-gray-light tw-bg-gray-light tw-py-4 tw-mt-8 tw-mb-6"
        :class="{ 'tw-border tw-border-error': totalAmountError }"
      >
        <p class="tw-text-center tw-w-full tw-text-gray tw-mb-1">
          You pay:
        </p>
        <p class="tw-text-2xl tw-text-secondary tw-text-center">
          {{
            formatAmountToDollar(
              Number(totalAmount) +
                Number(totalAmount * tokenInfo.buy_fee) / 100,
              2,
              "USD"
            )
          }}
        </p>
        <p
          v-if="totalAmountError"
          class="tw-absolute tw--top-4 tw-right-0 tw-text-error tw-text-xs"
        >
          the maximum value is {{ tradeBalance }}
        </p>
      </div>
      <Btn
        class="tw-mt-4"
        :title="'Place ' + tradeType.toLowerCase() + ' order'"
      />
    </form>
    <SmallModal
      v-if="showConfirmModal"
      class="tw-z-50"
      @close="showConfirmModal = !showConfirmModal"
    >
      <template v-slot:content>
        <h3 class="tw-text-xl tw-text-center tw-font-bold">Confirmation</h3>
        <div class="tw-bg-gray-light tw-rounded tw-px-4 tw-py-6 tw-mt-6">
          <div class="tw-flex tw-justify-between tw-items-center">
            <div>
              <p class="tw-text-gray">You are {{ tradeType }}ing</p>
              <h3 class="tw-text-">
                {{ payload.tokens }} {{ tokenInfo.name }} Token(s)
              </h3>
            </div>
            <img
              class="tw-h-10 tw-rounded"
              :src="tokenInfo.image"
              :alt="tokenInfo.name"
            />
          </div>
          <div class="tw-flex tw-justify-between tw-items-center tw-mt-6">
            <div>
              <p class="tw-text-gray">For</p>
              <h3 class="tw-text-">
                {{
                  tradeType === "Buy"
                    ? formatAmountToDollar(Number(totalAmount), 2, "USD")
                    : formatAmountToDollar(
                        Number(totalAmount) -
                          (Number(totalAmount) * tokenInfo.buy_fee) / 100,
                        2,
                        "USD"
                      )
                }}
              </h3>
            </div>
            <div>
              <p class="tw-text-gray">Transaction Fee</p>
              <div class="tw-flex tw-justify-between">
                <span class="tw-text- tw-text-right">
                  {{
                    formatAmountToDollar(
                      (Number(totalAmount) * tokenInfo.buy_fee) / 100,
                      2,
                      "USD"
                    )
                  }}
                </span>
                <span class="tw-text-right ">({{ tokenInfo.buy_fee }}%)</span>
              </div>
            </div>
          </div>
        </div>
        <PinConfirmation
          class="tw-mt-2"
          :btnTitle="tradeType"
          @confirmed="buyToken"
        />
      </template>
    </SmallModal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { formatAmountToDollar } from "@/utils/helpers";
import ToggleButton from "@/components/general/ToggleButton";
import NumberInput from "@/components/wallet/NumberInput.vue";
import SmallModal from "@/components/general/SmallModal.vue";
import PinConfirmation from "@/components/general/PinConfirmation.vue";
import Btn from "@/components/general/BtnComponent.vue";

export default {
  name: "SecondariesTokenAmountForm",

  components: {
    ToggleButton,
    NumberInput,
    SmallModal,
    PinConfirmation,
    Btn,
  },

  data() {
    return {
      advancedMode: false,
      showConfirmModal: false,
      dollarValid: false,
      tokenValid: false,
      totalAmountError: false,
      validInput: {
        dollarAmount: false,
        tokenAmount: false,
      },
      payload: {
        tokens: "",
        dollarAmount: "",
        limitPrice: "10",
      },
      secondariesType: "Market", // Market or Limit
      tradeType: "Buy",
      availableTokens: 0,
      minAmount: 10,
      totalAmount: "",
      maxNumber: 0,
    };
  },

  computed: {
    ...mapState({
      tradeBalance: (state) => state.organisationModule.tradeBalance,
      singleTokenBalance: (state) => state.Asset.singleTokenBalance.balance,
      tokenInfo: (state) => state.Asset.token,
    }),
  },

  mounted() {
    this.calculateDetails();
  },

  methods: {
    ...mapActions("organisationModule", ["tradeToken"]),
    ...mapActions(["showToast"]),

    formatAmountToDollar,

    async buyToken() {
      let payload = {};
      if (this.advancedMode) {
        payload = {
          id: this.tokenInfo._id,
          amount: Number(this.payload.tokens),
          price: Number(this.payload.dollarAmount),
          type: this.tradeType.toLowerCase(),
        };
      } else {
        payload = {
          id: this.tokenInfo._id,
          amount: Number(this.payload.tokens),
          type: this.tradeType.toLowerCase(),
        };
      }
      const response = await this.tradeToken(payload);
      if (response) {
        this.$emit("completed");
      }
    },

    showModal() {
      if (this.advancedMode && this.tradeType === "Buy") {
        if (this.totalAmount > this.tradeBalance) {
          this.totalAmountError = true;
          return;
        }
      }
      this.dollarValid = true;
      this.tokenValid = true;
      setTimeout(() => {
        if (!this.validInput.dollarAmount || !this.validInput.tokenAmount) {
          return;
        }
        this.showConfirmModal = !this.showConfirmModal;
      }, 500);
    },

    calculateDetails() {
      this.availableTokens =
        this.tokenInfo.raise_amount / this.minAmount - this.singleTokenBalance;
      switch (this.advancedMode) {
        case true:
          if (this.tradeType === "Buy") {
            this.maxNumber = this.tradeBalance;
          }
          if (this.tradeType === "Sell") {
            this.maxNumber = Infinity;
          }
          break;
        case false:
          if (this.tradeType === "Buy") {
            this.maxNumber = this.tradeBalance;
          }
          if (this.tradeType === "Sell") {
            this.maxNumber =
              this.tokenInfo.price.exchange * this.singleTokenBalance;
          }
          break;

        default:
          break;
      }
    },

    switchTradeType(tradeType) {
      this.totalAmountError = false;
      this.tradeType = tradeType;
      this.payload.tokens = "";
      this.payload.dollarAmount = "";
      this.totalAmount = "";
      this.calculateDetails();
    },

    setTokenAmount(amount) {
      this.totalAmountError = false;
      switch (this.advancedMode) {
        case true:
          this.payload.tokens = amount;
          this.totalAmount = Number(amount * this.payload.dollarAmount);
          break;
        case false:
          this.payload.tokens = amount;
          this.payload.dollarAmount = String(
            amount * this.tokenInfo.price.exchange
          );
          this.totalAmount = amount * this.tokenInfo.price.exchange;
          break;

        default:
          break;
      }
    },

    setDollarAmount(amount) {
      this.totalAmountError = false;
      switch (this.advancedMode) {
        case true:
          this.payload.dollarAmount = amount;
          this.totalAmount = Number(amount * this.payload.tokens);
          break;
        case false:
          this.payload.dollarAmount = amount;
          this.payload.tokens = String(amount / this.tokenInfo.price.exchange);
          this.totalAmount = Number(amount);
          break;

        default:
          break;
      }
    },

    dollarAmountValid(valid) {
      this.validInput.dollarAmount = valid;
    },

    tokenAmountValid(valid) {
      this.validInput.tokenAmount = valid;
    },

    validate() {
      this.dollarValid = false;
      this.tokenValid = false;
    },

    toggleMode(val) {
      this.totalAmountError = false;
      this.advancedMode = val;
      this.payload.tokens = "";
      this.payload.dollarAmount = "";
      this.totalAmount = "";
      this.calculateDetails();
    },
  },

  watch: {
    advancedMode(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.payload.tokens = "";
        this.payload.dollarAmount = "";
        this.totalAmount = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
